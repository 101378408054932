import React from 'react';
import withStore from "@/hocs/withStore";
import BaseLayout from "@/Layouts/BaseLayout";
import '@/Pages/NotFound/scss/index.scss';
import LinkLoad from "@/Layouts/LinkLoad";

const NotFound = ({h1, ...props}) => {
    return <BaseLayout {...props}>
        <div className={`not-found`}>
            <div className={`container`}>
                <div className={`not-found__message`}>
                    <div className={`not-found__title`}>
                        <h1>404</h1>
                    </div>
                    <div className={`not-found__text-wrap`}>
                        <p className={`not-found__text`}>Увы... Такой страницы нет.</p>
                        <p className={`not-found__descr`}>
                            Но есть целый каталог украшений!<br />
                            Проверьте написание адреса. Всё верно?<br />
                            Возможно, страница была удалена.
                        </p>
                        <LinkLoad className={`link`}
                                  href={`/catalog`}
                        >Перейти в каталог</LinkLoad>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
};

export default withStore(NotFound);
